import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
//import { calendarApi } from 'src/__fakeApi__/calendarApi'
import { subDays } from 'date-fns'
import type { AppThunk } from '../store'
import type { CalendarEvent } from '../types/calendar'
import axios from 'src/utils/axios'
interface CalendarState {
	events: CalendarEvent[]
	isModalOpen: boolean
	selectedEventId: string | null
	selectedRange: {
		start: number
		end: number
	} | null
}
interface EventData {
	allDay: boolean
	resourceId: string
	description: string
	end: number
	start: number
	title: string
}
const initialState: CalendarState = {
	events: [],
	isModalOpen: false,
	selectedEventId: null,
	selectedRange: null,
}

const slice = createSlice({
	name: 'calendar',
	initialState,
	reducers: {
		getEvents(
			state: CalendarState,
			action: PayloadAction<CalendarEvent[]>
		): void {
			state.events = action.payload || []
		},
		createEvent(
			state: CalendarState,
			action: PayloadAction<CalendarEvent>
		): void {
			state.events.push(action.payload)
		},
		selectEvent(state: CalendarState, action: PayloadAction<string>): void {
			state.isModalOpen = true
			state.selectedEventId = action.payload
		},
		updateEvent(
			state: CalendarState,
			action: PayloadAction<CalendarEvent>
		): void {
			const event = action.payload

			state.events = state.events.map((_event) => {
				if (_event.id === event.id) {
					return event
				}

				return _event
			})
		},
		deleteEvent(state: CalendarState, action: PayloadAction<string>): void {
			state.events = state.events.filter((event) => event.id !== action.payload)
		},
		selectRange(
			state: CalendarState,
			action: PayloadAction<{ start: number; end: number }>
		): void {
			const { start, end } = action.payload

			state.isModalOpen = true
			state.selectedRange = {
				start,
				end,
			}
		},
		openModal(state: CalendarState): void {
			state.isModalOpen = true
		},
		closeModal(state: CalendarState): void {
			state.isModalOpen = false
			state.selectedEventId = null
			state.selectedRange = null
		},
	},
})

export const { reducer } = slice

export const getEvents =
	(date: Date): AppThunk =>
	async (dispatch): Promise<void> => {
		//console.log('Date:', subDays(date, 30), subDays(date, 30).getTime())

		const response = await axios.get(
			'/api/calendar/user/' + subDays(date, 30).getTime()
		)

		dispatch(slice.actions.getEvents(response.data.events as []))
	}

export const createEvent =
	(createData: EventData): AppThunk =>
	async (dispatch): Promise<void> => {
		//console.log('created event', createData)
		try {
			const response = await axios.post('/api/calendar/newevent', createData)
			dispatch(slice.actions.createEvent(response.data.event))
		} catch (error) {
			console.log(error)
		}
	}

export const selectEvent =
	(eventId?: string): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(slice.actions.selectEvent(eventId))
	}

export const updateEvent =
	(eventId: string, update: any): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			const response = await axios.patch(
				'/api/calendar/event/' + eventId,
				update
			)
			/* 		const data = await calendarApi.updateEvent({
			eventId,
			update,
		}) */

			dispatch(slice.actions.updateEvent(response.data.event))
		} catch (error) {
			console.log(error)
		}
	}

export const deleteEvent =
	(eventId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		//await calendarApi.deleteEvent(eventId)
		await axios.delete('/api/calendar/event/' + eventId)
		dispatch(slice.actions.deleteEvent(eventId))
	}

export const selectRange =
	(start: number, end: number): AppThunk =>
	(dispatch): void => {
		dispatch(slice.actions.selectRange({ start, end }))
	}

export const openModal =
	(): AppThunk =>
	(dispatch): void => {
		dispatch(slice.actions.openModal())
	}

export const closeModal =
	(): AppThunk =>
	(dispatch): void => {
		dispatch(slice.actions.closeModal())
	}

export default slice
