export const SPORTSCate = [
	'Archery',
	'Baseball',
	'Basketball',
	'Cheerleading',
	'Dance',
	'Figure skating',
	'Field Hockey',
	'Football',
	'Golf',
	'Gymnastics',
	'Rhythmic Gymnastics',
	'Ice Kockey',
	'Karate',
	'Lacrosse',
	'Rowing',
	'Snowboarding',
	'Soccer',
	'Surfing',
	'Swimming',
	'Table Tennis',
	'Tennis',
	'Track and Field',
	'Volleyball',
	'Water Polo',
]
export const ARTCate = [
	'Drawing/Painting',
	'Piano/keyboard',
	'Guitar',
	'Violin',
	'Drum',
	'Saxophone',
	'Flute',
	'Clarinet',
	'Cello',
	'Voice',
	'Trumpet',
	'Dancing',
	'Ballet',
]
export const STEMCate = ['Math', 'Coding', 'Physics', 'Chemistry']
